<template>
  <div id="pdf">
    <div id="demo"></div>
  </div>
</template>
<script>
import Pdfh5 from "pdfh5";
import { download } from "../utils/api";
export default {
  name: "pdf",
  data() {
    return {
      pdfh5: null,
    };
  },

  mounted() {
    
    if(this.$route.query.id){
      let url = `${download}${this.$route.query.productId}/${this.$route.query.id}/2`
      this.pdf(url)
    }else if(this.$route.query.type == 'native'){
      document.title = '客户告知书';
      //客户告知书
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/pdf/%E4%B9%90%E8%8D%90%E4%BF%9D%E5%AE%A2%E6%88%B7%E5%91%8A%E7%9F%A5%E4%B9%A6.pdf');
    }else if(this.$route.query.type == 'privacyPolicy'){
      document.title = '隐私条款';
      //隐私条款
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/pdf/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96-%E4%B9%90%E8%8D%90%E4%BB%A3.pdf');
    }else if(this.$route.query.type == 'xmInsuranceClause'){
      document.title = '保险条款';
      //信美保险条款
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/applet/xmInsuranceClause.pdf');
    }else if(this.$route.query.type == 'xmInsuranceInformation'){
      document.title = '投保须知';
      //信美投保须知
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/applet/xmInsuranceInformation.pdf');
    }else if(this.$route.query.type == 'xmAutomatedTransit'){
      document.title = '银行自动转账授权书';
      //自动转账授权书
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/applet/xmAutomatedTransit.pdf');
    }else if(this.$route.query.type == 'xmServiceDetails'){
      document.title = '服务详情';
      //服务详情
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/applet/xmServiceDetails.pdf');
    }else if(this.$route.query.type == 'xmImportantNote'){
      document.title = '重要提示';
      //健康告知
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/applet/xmzyts.pdf');
    }else{
      //健康告知
      // this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/pdf/%E5%AE%88%E6%8A%A4%E7%A5%9E%E7%BB%88%E8%BA%AB%E5%AF%BF2.0%E5%81%A5%E5%91%8A.pdf')
    }
  },
  methods: {
    //下载查看
    pdf(url) {
      let pdfUrl = url;
      //实例化
      this.pdfh5 = new Pdfh5("#demo", {
        pdfurl: pdfUrl,
        lazy: true,
      }).on("complete", function (status, msg, time) {
        //监听完成事件
        console.log(
          "状态：" +
            status +
            "，信息：" +
            msg +
            "，耗时：" +
            time +
            "毫秒，总页数：" +
            this.totalNum
        );
      });
    },
  },
};
</script>
 
<style lang="scss" scoped>
#pdf {
  width: 100%;
  /deep/ .pinch-zoom-container {
    height: 100vh !important;
  }
  /deep/ .pageContainer {
    width: 100% !important;
  }
  /deep/ img {
    width: 100% !important;
  }
}
</style>